.spinner-dots {
  display: flex;
  gap: 10px;
  margin: 0 auto;
}

.spinner-dots > div {
  width: 18px;
  height: 18px;
  background-color: var(--white1);

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-dots__bounce1 {
  -webkit-animation-delay: -0.32s !important;
  animation-delay: -0.32s !important;
}

.spinner-dots__bounce2 {
  -webkit-animation-delay: -0.16s !important;
  animation-delay: -0.16s !important;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.info-button {
  position: relative;
  cursor: pointer;
}

.info-button__icon {
  margin-left: 10px;
}

.info-button:hover .info-button__text {
  display: flex;
}

.info-button__text {
  position: absolute;
  padding: 10px 20px;
  border-radius: 20px;
  max-width: 288px;
  min-width: 288px;
  background: var(--black9);
  display: none;
  z-index: 2;
  left: 40px;
  top: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: var(--white1);
  text-transform: none;
}

.info-button__icon-close {
  width: 16px;
  height: 16px;
  margin-top: 10px;
}

@media (max-width: 576px) {
  .info-button__text {
    left: -2px;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 101%;
    min-width: 101%;
  }
}

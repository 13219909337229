.collection-card__card-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.collection-card__card {
  width: 200px;
  height: 319px;
  display: inline;
  z-index: 2;
  object-fit: cover;
}

.collection-card__card-state {
  display: inline-block;
  padding: 2px 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 12px;
  text-transform: uppercase;
  --bg-color-start: var(--purple2);
  --bg-color-end: var(--pink1);
  background: linear-gradient(
    135deg,
    var(--bg-color-start) 0%,
    var(--bg-color-end) 100%
  );
}

.collection-card__card-state--offering {
  --bg-color-start: var(--blue1);
  --bg-color-end: var(--purple2);
}

.collection-card__card-name {
  font-weight: 600;
  font-size: 12px;
  line-height: 125%;
  color: var(--white1);
  max-width: 180px;
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;
}

.collection-card__card-hover-wrapper {
  position: absolute;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
  width: 200px;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
}

.collection-card__card-wrapper:hover .collection-card__card-hover-wrapper {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s, visibility 0.2s;
}

.collection-card__card-hover-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 4;
  top: 20px;
  padding: 0 20px;
}

.collection-card__card-hover-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  margin: 20px 0;
  text-align: center;
  color: var(--white1);
}

.collection-card__card-hover-state {
  padding: 5px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--white1);
  text-transform: uppercase;
  --bg-color-start: var(--purple2);
  --bg-color-end: var(--pink1);
  background: linear-gradient(
    135deg,
    var(--bg-color-start) 0%,
    var(--bg-color-end) 100%
  );
}

.collection-card__card-hover-state--offering {
  --bg-color-start: var(--blue1);
  --bg-color-end: var(--purple2);
}

@media (max-width: 1000px) {
  .collection-card__card-wrapper {
    transform: none;
    padding-left: 0;
  }

  .collection-card__card {
    margin: 0 auto;
  }
}

@media (max-width: 800px) {
  .collection-card__card-wrapper {
    transform: none;
  }

  .collection-card__card {
    width: 288px;
    height: 360px;
    margin: 0 auto;
    object-fit: contain;
  }
}

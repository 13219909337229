.home {
}

.home__new-feature {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 61px;
  background: linear-gradient(135deg, var(--purple2) 0%, var(--pink1) 100%);
  width: 100%;
  padding: 0 20px;
  margin-top: 104px;
}

.home__new-feature-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--white1);
}

.home__consign-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  width: 186px;
  height: 41px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--white1);
  border: solid 3px transparent;
  background-image: linear-gradient(var(--black10) 0%, var(--black10) 100%),
    linear-gradient(90.57deg, var(--purple3) 0%, var(--green1) 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

@media (max-width: 390px) {
  .home__new-feature-text {
    font-size: 12px;
  }
}

.consign-with-us-third-step__info-text {
  width: 285px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black9);
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--green3);
  margin-top: -10px;
  margin-bottom: -10px;
}
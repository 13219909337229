.collect__wrapper {
  max-width: 1160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 140px;
  z-index: 2;
  position: relative;
}

.collect__cards {
  height: 294px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  border-radius: 20px;
  margin-bottom: 40px;
}

.collect__title {
  font-weight: 600;
  font-size: 33px;
  line-height: 40px;
  color: var(--white1);
}

.collect__text {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: var(--white1);
  margin-top: 40px;
  margin-bottom: 40px;
}

.collect__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.collect__card-title {
  font-weight: 600;
  font-size: 16px;
  color: var(--white1);
}

.collect__slider-wrapper {
  max-width: 100%;
}

.collect__wrapper .slick-prev {
  z-index: 2;
  width: 50px;
  height: 50px;
  left: 0;
}

.collect__wrapper .slick-next {
  z-index: 2;
  width: 50px;
  height: 50px;
  right: 0;
}

.collect__wrapper .slick-dots {
  bottom: -55px;
}

.collect__wrapper .slick-dots li.slick-active button:before {
  color: var(--purple3);
  opacity: 1;
}

.collect__wrapper .slick-dots li button:before {
  font-size: 10px;
  color: var(--white2);
  opacity: 1;
}

@media (max-width: 1200px) {
  .collect__cards {
    gap: 20px;
  }
  .collect__wrapper {
    max-width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (max-width: 900px) {
  .collect__title {
    font-weight: 600;
    font-size: 23px;
    line-height: 150%;
    text-align: center;
  }

  .collect__text {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    margin-top: 10px;
    max-width: 275px;
    text-align: center;
  }

  .collect__wrapper {
    padding: 0 20px;
  }

  .collect__card {
    height: 210px;
  }

  .collect__card-content {
    width: 200px;
    height: 210px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    border-radius: 20px;
  }

  .collect__slider-wrapper {
    margin-bottom: 0;
  }
}

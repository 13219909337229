.form-submit {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 28px;
  line-height: 150%;
  color: var(--white1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 274px;
  height: 68px;
  box-shadow: 0px 5px 30px rgba(228, 221, 250, 0.4);
  border-radius: 40px;
  background: var(--purple4);
  border: 1px solid var(--white4);
}

.form-submit:hover {
  background-color: var(--purple5);
}

@media (max-width: 800px) {
  .form-submit {
    max-width: 240px;
    height: 59px;
    font-size: 21px;
    width: 100%;
  }
}

.blog-card__card {
  width: 717px;
  min-height: 220px;
  display: flex;
  flex-direction: row;
  border: 2px solid var(--purple3);
  background: var(--black2);
  background-color: transparent;
  padding: 24px;
  cursor: pointer;
  border-radius: 0px 36px;
  gap: 20px;
}

.blog-card__card-content {
  display: flex;
  flex-direction: column;
}

.blog-card__card-img {
  max-width: 352px;
  min-width: 352px;
  height: 172px;
  min-height: 172px;
  border-radius: 0px 0px 0px 20px;
  object-fit: cover;
}

.blog-card__card-title {
  font-weight: 600;
  font-size: 24px;
  color: var(--blue3);
  text-transform: uppercase;
  line-height: 150%;
  text-shadow: 0px 0px 5px var(--purple2);
  letter-spacing: 0.04em;
}

.blog-card__card-description {
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  margin-top: 10px;
  color: var(--grey1);
  margin-bottom: 20px;
}

.blog-card__card-date {
  font-weight: 400;
  font-size: 8px;
  line-height: 150%;
  color: var(--grey1);
  font-style: italic;
  margin-top: 5px;
}

.blog-card__card .buttons__button--purple {
  align-self: center;
}

@media (max-width: 800px) {
  .blog-card__card-wrapper {
    max-width: 310px;
    width: 310px;
    min-height: 176px;
    display: flex;
    flex-direction: column;
    border: 2px solid var(--purple3);
    background-color: transparent;
    padding-left: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 16px;
    cursor: pointer;
    border-radius: 0px 36px;
    gap: 10px;
    margin: 0 auto;
  }

  .blog-card__card {
    border: none;
    height: auto;
    padding: 0;
    width: 100%;
  }

  .blog-card__card:hover {
    background-color: transparent;
  }

  .blog-card__card-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
  }

  .blog-card__card-wrapper .buttons__button--purple {
    align-self: flex-end;
  }

  .blog-card__card-img {
    max-width: 278px;
    min-width: 278px;
    height: 136px;
    min-height: 136px;
    border-radius: 0px 36px;
    object-fit: cover;
  }
}

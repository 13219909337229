.pagination {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.pagination__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.pagination__icon,
.pagination__page {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination__icon {
  background: var(--white1);
}

.pagination__icon svg:not(:root) {
  width: 18px;
  height: 18px;
}

.pagination__icon svg * {
  stroke: var(--purple3);
}

.pagination__icon--disabled {
  cursor: not-allowed;
  user-select: none;
}

.pagination__icon:hover {
  border: 1px solid var(--purple3);
}

.pagination__icon--disabled:hover,
.pagination__icon--disabled {
  opacity: 0.5;
}

.pagination__page {
  background: var(--black2);
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  border: 1px solid var(--white1);
}

.pagination__page:hover,
.pagination__page--active,
.pagination__page--active:hover {
  border: 1px solid var(--purple3);
}

.pagination__pages {
  display: flex;
  align-items: center;
  line-height: 1rem;
  gap: 8px;
}

.pagination__previous,
.pagination__next {
  display: flex;
  align-items: center;
  gap: 8px;
}

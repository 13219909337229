:root {
  --white1: #ffffff;
  --white2: #e4ddfa;
  --white3: #e4e5e9;
  --white4: #f1eefd;
  --black1: #000000;
  --black2: #000f26;
  --black3: #2c2f36;
  --black4: #191721;
  --black5: #1a1238;
  --black6: #241f37;
  --black7: #17161d;
  --black8: #201c2e;
  --black9: #191b20;
  --black10: #191531;
  --grey1: #caccd2;
  --grey2: #b1b4bb;
  --grey3: #6b6e79;
  --grey4: #d9d9d9;
  --purple1: #ae9cf3;
  --purple2: #825af6;
  --purple3: #7c5ced;
  --purple4: #251b46;
  --purple5: #32245e;
  --purple6: #221d33;
  --purple7: #6249bd;
  --purple8: #957bef;
  --blue1: #3f8cff;
  --blue2: #5289f7;
  --blue3: #c9ddff;
  --pink1: #ff98e5;
  --green1: #53d660;
  --green2: #35ff49;
  --green3: #2ca939;
  --red1: #e02323;
  --globalBackground: linear-gradient(
    180deg,
    #000f26 0%,
    #000f26 26.56%,
    #000d21 50%,
    #28164e 77.08%,
    #131a3c 99.9%
  );
}

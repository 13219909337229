.collections__wrapper {
  margin-top: 140px;
  max-width: 1030px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: relative;
  z-index: 2;
}

.collections__title {
  font-weight: 600;
  font-size: 33px;
  line-height: 40px;
  color: var(--white1);
  margin-bottom: 40px;
  text-transform: uppercase;
}

.collections__wrapper .slick-prev {
  z-index: 2;
  width: 50px;
  height: 50px;
}

.collections__wrapper .slick-next {
  z-index: 2;
  width: 50px;
  height: 50px;
}

.collections__wrapper .slick-dots {
  bottom: -55px;
}

.collections__wrapper .slick-dots li.slick-active button:before {
  color: var(--purple3);
  opacity: 1;
}

.collections__wrapper .slick-dots li button:before {
  font-size: 10px;
  color: var(--white2);
  opacity: 1;
}

.collections__explore-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

@media (max-width: 1100px) {
  .collections__wrapper {
    max-width: 100%;
  }
  .collections__wrapper .slick-next {
    right: 0;
  }

  .collections__wrapper .slick-prev {
    left: 0;
  }
}

@media (max-width: 800px) {
  .collections__wrapper {
    margin-top: 140px;
    padding: 0 20px;
  }

  .collections__title {
    font-weight: 600;
    font-size: 23px;
    line-height: 150%;
  }

  .collections__wrapper .slick-prev {
    left: 0;
  }

  .collections__wrapper .slick-next {
    right: 0;
  }
}

.buttons__button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: var(--white1);
  height: 40px;
  cursor: pointer;
  border-radius: 20px;
}

.buttons__button--dark {
  background-color: var(--purple4);
  border: 1px solid var(--white4);
}

.buttons__button--dark:hover {
  background-color: var(--purple5);
}

.buttons__button--purple {
  background-color: var(--black2);
  border: 3px solid var(--purple3);
  font-weight: 600;
  font-size: 12px;
}

.buttons__button--purple:hover {
  background-color: var(--purple3);
}

.buttons__button--gradient {
  background: linear-gradient(131.67deg, var(--blue1) 0%, var(--purple2) 100%);
  border: 1px solid var(--white4);
}

.buttons__share-icon {
  width: 20.54px;
  height: 20.54px;
  margin-right: 20px;
}

.buttons__button--share {
  height: 36.54px;
  font-weight: 600;
  font-size: 14px;
  border: 3px solid var(--purple3);
}

.buttons__button--share:hover {
  background-color: var(--purple3);
}

.buttons__button--share:hover .share-line1,
.buttons__button--share:hover .share-line2,
.buttons__button--share:hover .share-line3 {
  fill: var(--white1);
}

.buttons__button--share:hover .share-line4 {
  stroke: var(--white1);
}

@media (max-width: 576px) {
  .buttons__button--share {
    background-color: var(--purple3);
  }

  .buttons__button--share .share-line1,
  .buttons__button--share .share-line2,
  .buttons__button--share .share-line3 {
    fill: var(--white1);
  }

  .buttons__button--share .share-line4 {
    stroke: var(--white1);
  }

  .buttons__button--dark {
    background-color: var(--purple5);
  }
  .buttons__button--purple {
    background-color: var(--purple3);
  }
}

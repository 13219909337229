.fee-schedule {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-top: 104px;
}

.fee-schedule__content-wrapper {
  max-width: 980px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 60px 24px;
}

.fee-schedule__header-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.fee-schedule__date-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--white1);
  margin-top: 40px;
  margin-bottom: 20px;
}

.fee-schedule__header-text {
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: var(--white1);
}

.fee-schedule__table-wrapper {
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 490px;
  border: 1px solid #ffffff;
  margin: 40px 0;
  padding: 30px 20px 50px;
  gap: 40px;
}

.fee-schedule__table-section-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.fee-schedule__table-section-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 25.34px;
  color: var(--white1);
}

.fee-schedule__table-section-row {
  display: flex;
  gap: 120px;
  width: 100%;
}

.fee-schedule__table-section-left-part {
  width: 193px;
  max-width: 193px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--white1);
}

.fee-schedule__table-section-full-part {
  width: 100%;
}

.fee-schedule__table-section-right-part {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--white1);
  width: 137px;
  max-width: 137px;
}

.fee-schedule__additional-text-wrapper {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fee-schedule__additional-text-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--white1);
}

.fee-schedule__additional-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--white1);
}

@media (max-width: 980px) {
  .fee-schedule__table-section-row {
    gap: 60px;
  }

  .fee-schedule__table-section-left-part {
    width: 200px;
  }
  .fee-schedule__table-section-right-part {
    width: 95px;
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }

  .fee-schedule__header-wrapper,
  .fee-schedule__additional-text-wrapper,
  .fee-schedule__date-text {
    padding: 0 24px;
  }
}

@media (max-width: 800px) {
  .fee-schedule__table-section-row {
    gap: 0px;
  }
}

.about-us {
  margin-top: 104px;
  padding-top: 20px;
}

.about-us__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-us__header-logo {
  width: 350px;
  height: 322px;
}

.about-us__header-site-name {
  margin-top: 10px;
  width: 499px;
  height: 72px;
}

.about-us__header-text {
  margin-top: 10px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: var(--white1);
  max-width: 555px;
  text-align: center;
}

.about-us__logo-wrapper {
  display: flex;
}

.about-us__logo-left-part {
  font-weight: 300;
  font-size: 48px;
  line-height: 150%;
  color: var(--white1);
}

.about-us__logo-right-part {
  font-weight: 600;
  font-size: 48px;
  line-height: 150%;
  color: var(--white1);
  padding-left: 10px;
}


@media (max-width: 800px) {
  .about-us__header-logo {
    width: 217px;
    height: 200px;
  }

  .about-us__header-site-name {
    width: 250px;
    height: 36px;
  }

  .about-us__header-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    padding: 0 36px;
  }
}

@media (max-width: 576px) {
  .about-us__logo-left-part,
  .about-us__logo-right-part {
    font-size: 24px;
  }

  .about-us__logo-right-part {
    padding-left: 5px;
  }
}

.footer {
  width: 100%;
  margin-top: 40px;
}

.footer__content {
  width: 100%;
  padding-left: 111px;
  padding-right: 121px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.footer__logo-email-column {
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-width: 0px;
  align-self: stretch;
}

.footer__logo {
  width: 288px;
}

.footer__links {
  display: flex;
  gap: 140px;
  margin-top: 20px;
}

.footer__navigation-column,
.footer__resources-column,
.footer__contact-us-column {
  display: flex;
  flex-direction: column;
}

.footer__navigation-column {
  gap: 40px;
}

.footer__resources-column,
.footer__contact-us-column {
  gap: 20px;
}

.footer__navigation-link {
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  color: var(--white1);
  cursor: pointer;
  width: max-content;
  position: relative;
}

.footer__navigation-link:hover.footer__navigation-link::after,
.footer__navigation-link--selected.footer__navigation-link::after,
.footer__resources-link:hover.footer__resources-link::after,
.footer__resources-link--selected.footer__resources-link::after,
.footer__contact-us-link:hover.footer__contact-us-link::after,
.footer__contact-us-link--selected.footer__contact-us-link::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  background: var(--purple3);
  height: 2px;
  border-radius: 5px;
}

.footer__column-title {
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  color: var(--white1);
}

.footer__resources-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--white1);
  cursor: pointer;
  width: max-content;
  position: relative;
}

.footer__contact-us-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--white1);
  cursor: pointer;
  width: max-content;
  position: relative;
}

.footer__contact-us-socials {
  display: flex;
  gap: 20px;
}

.footer__contact-us-social-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.footer__bottom-content {
  height: 74px;
  width: 100%;
  border-top: 1px solid var(--white1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 168px;
  padding-right: 178px;
}

.footer__company {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--white1);
}

.footer__policy-links {
  display: flex;
  gap: 80px;
}

.footer__policy-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--white1);
  cursor: pointer;
}

.footer__empty {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4px;
}

.footer__divider {
  width: 100%;
  height: 1px;
  background: var(--white1);
}

.footer__name {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--white1);
  margin-top: 10px;
  margin-bottom: 20px;
}

@media (max-width: 1300px) {
  .footer__content {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 1200px) {
  .footer__links {
    gap: 30px;
  }

  .footer__content {
    padding-left: 30px;
    padding-right: 30px;
  }

  .footer__bottom-content {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 800px) {
  .footer {
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  .footer__content {
    flex-direction: column;
    align-items: center;
    height: auto;
    justify-content: flex-start;
    padding-left: 22.5px;
    padding-right: 22.5px;
    gap: 0px;
  }

  .footer__bottom-content {
    height: auto;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .footer__links {
    flex-direction: row;
    gap: 60px;
    width: 100%;
    justify-content: space-between;
    margin-top: 26px;
  }

  .footer__navigation-column,
  .footer__resources-column,
  .footer__contact-us-column {
    align-items: flex-start;
    justify-content: space-between;
  }

  .footer__resources-column,
  .footer__contact-us-column {
    gap: 20px;
  }

  .footer__column-title {
    margin-bottom: -4px;
  }

  .footer__logo {
    width: 288px;
    height: 79px;
  }

  .footer__contact-us-column {
    margin-top: 50px;
  }

  .footer__contact-us-socials {
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .footer__resources-link,
  .footer__contact-us-link {
    padding-left: 20px;
  }

  .footer__policy-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
  }

  .footer__empty {
    padding: 0 20px;
  }
}

@media (max-width: 415px) {
  .footer {
    padding-left: 5px;
    padding-right: 5px;
  }

  .footer__content {
    padding-left: 10px;
    padding-right: 10px;
  }

  .footer__links {
    gap: 10px;
  }
}

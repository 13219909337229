.input-container {
  display: flex;
  flex-direction: column;
}

.input-container__label {
  text-transform: uppercase;
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
  font-weight: 600;
}

.input-container__error {
  color: var(--red1);
  margin-top: 10px;
  font-size: 16px;
}

.inputs__input {
  border: 1px solid;
  font-size: 16px;
  min-width: 0px;
}

.inputs__input--light {
  border-color: var(--purple3);
  background-color: white;
  min-height: 32px;
}

.inputs__input--dark {
  border-color: var(--white1);
  background-color: var(--black3);
  padding: 10px 20px;
  text-transform: uppercase;
  color: var(--grey2);
  font-size: 18px;
  font-weight: 600;
}
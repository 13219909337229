.form-container {
  width: 520px;
  min-height: fit-content;
  background-color: var(--black6);
  border: 1px solid var(--white1);
  border-radius: 20px;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.form-container__error {
  margin-top: 20px;
  font-size: 15px;
  color: var(--red1);
}

.form-container__title {
  font-size: 33px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  margin-bottom: 50px;
}

.form-container__form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.form-container__form__name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-container__form .input-container {
  margin-bottom: 40px;
}

@media (max-width: 600px) {
  .form-container__title {
    font-size: 23px;
    margin-bottom: 30px;
  }
  .form-container__form .input-container {
    margin-bottom: 30px;
  }
  .form-container__form .input-container:first-child {
    max-width: 200px;
  }
  .form-container__form .input-container:nth-child(2) {
    max-width: 200px;
  }
  .form-container__form .input-container__label {
    font-size: 14px;
  }
  .form-container {
    width: 100%;
    max-width: 520px;
  }
}

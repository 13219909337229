.drops-list {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1220px;
}

.drops-list__spinner-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px 30px;
}

.drops-list__no-data {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px 30px;
  font-weight: 600;
  font-size: 34px;
  line-height: 1.5;
  text-align: center;
}

.drops-list__item-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 40px;
}

.drops-list-pagination__container {
  margin-top: 40px;
}

@media (max-width: 1250px) {
  .drops-list {
    margin: 0 92px;
  }
}

@media (max-width: 800px) {
  .drops-list {
    margin: 0 20px;
  }

  .drops-list__no-data {
    font-size: 21px;
  }

  .drops-list__item-wrapper {
    gap: 20px;
  }
}

@media (max-width: 375px) {
  .drops-list__no-data {
    padding: 80px 20px;
  }
}

.blogs-section {
  max-width: 1160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 108px;
  z-index: 2;
  position: relative;
}

.blogs-section__title {
  font-weight: 600;
  font-size: 33px;
  line-height: 40px;
  color: var(--white1);
}

.blogs-section__cards {
  display: flex;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  flex-direction: column;
}

.blogs-section .slick-prev {
  z-index: 2;
  width: 50px;
  height: 50px;
  left: 0;
}

.blogs-section .slick-next {
  z-index: 2;
  width: 50px;
  height: 50px;
  right: 0;
}

.blogs-section .slick-dots {
  bottom: -55px;
}

.blogs-section .slick-dots li.slick-active button:before {
  color: var(--purple3);
  opacity: 1;
}

.blogs-section .slick-dots li button:before {
  font-size: 10px;
  color: var(--white2);
  opacity: 1;
}

@media (max-width: 800px) {
  .blogs-section__slider-wrapper {
    max-width: 100%;
    margin-top: 40px;
    margin-bottom: 25px;
  }

  .blogs-section__title {
    font-weight: 600;
    font-size: 23px;
    line-height: 150%;
  }
}

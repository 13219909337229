.platform {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-top: 104px;
}

.platform__join-us-wrapper {
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 472px;
  position: relative;
  text-align: center;
}

.platform__join-us-title {
  font-weight: 600;
  font-size: 48px;
  line-height: 150%;
  text-shadow: -5px 5px 15px rgba(255, 255, 255, 0.45);
  color: var(--white1);
  position: relative;
  z-index: 2;
}

.platform__auth-buttons {
  display: flex;
  gap: 40px;
  position: relative;
  z-index: 2;
  margin-top: 48px;
}

.platform__mac-image {
  height: 100%;
  width: 750px;
  position: absolute;
  opacity: 0.5;
}

.platform__contacts-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 220px;
  height: 172px;
  background: var(--purple3);
}

.platform__contacts-left-part,
.platform__contacts-right-part {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.platform__contacts-row {
  display: flex;
  gap: 10px;
  align-items: center;
}

.platform__contacts-circle {
  height: 16px;
  width: 16px;
}

.platform__contacts-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--white1);
}

.platform__contacts-wrapper {
  margin-bottom: 40px;
}

.platform__information-section-wrapper {
  display: flex;
  margin-bottom: 40px;
  height: 620px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 120px;
}

.platform__information-img {
  height: 500px;
  width: 500px;
}

.platform__information-section-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 480px;
}

.platform__information-section-title {
  font-weight: 600;
  font-size: 33px;
  line-height: 40px;
  text-transform: uppercase;
  color: var(--white1);
}

.platform__information-section-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: var(--white1);
}

.platform__collect-together {
  height: 280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;
  background-color: rgba(26, 18, 56, 0.25);
  position: relative;
  text-align: center;
  margin-top: 40px;
}

.platform__collect-together-title {
  font-weight: 600;
  font-size: 33px;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: var(--white1);
  position: relative;
  z-index: 2;
}

.platform__collect-img {
  width: 400px;
  height: 300px;
  position: absolute;
  opacity: 0.2;
}

.platform__collect-together-button {
  position: relative;
  z-index: 2;
}

.platform__join-us-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--white1);
  margin-top: 10px;
}

@media (max-width: 900px) {
  .platform__contacts-wrapper {
    flex-direction: column;
    gap: 10px;
    height: 274px;
    margin-bottom: 80px;
  }
  .platform__information-section-wrapper {
    flex-direction: column;
    height: auto;
    padding: 0 40px;
    gap: 20px;
    margin-bottom: 120px;
  }

  .platform__information-section-wrapper:last-child {
  }

  .platform__information-img {
    height: 270px;
    width: 270px;
  }

  .platform__information-section-text-wrapper {
    width: 100%;
    gap: 20px;
  }

  .platform__information-section-title {
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    text-align: center;
  }

  .platform__information-section-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
  }
  .platform__join-us-wrapper {
    padding: 0 16px;
  }
  .platform__mac-image {
    width: calc(100% - 32px);
  }
  .platform__contacts-left-part,
  .platform__contacts-right-part {
    width: 292px;
  }

  .platform__collect-together {
    margin-top: 0;
  }
}

@media (max-width: 576px) {
  .platform__mac-image {
    height: 265px;
  }

  .platform__join-us-wrapper {
    justify-content: flex-start;
    height: 533px;
  }
  .platform__join-us-title {
    margin-top: 240px;
    font-weight: 600;
    font-size: 33px;
    line-height: 150%;
  }

  .platform__auth-buttons {
    margin-top: 40px;
  }

  .platform__collect-together-title {
    font-weight: 600;
    font-size: 19px;
    line-height: 150%;
    letter-spacing: -0.02em;
  }

  .platform__collect-together {
    gap: 40px;
    height: 218px;
    padding: 0 40px;
  }

  .platform__collect-img {
    width: auto;
  }
}

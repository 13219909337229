.drops-page {
  display: flex;
  flex-direction: column;
  padding: 0px 0 100px;
  margin-top: 104px;
}

.drops-page .drops-header {
  margin-bottom: 40px;
}

.drops-page__search-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.drops-page .drops-list {
  margin-top: 100px;
}

@media (max-width: 800px) {
  .drops-page {
    padding: 40px 0;
  }

  .drops-page .drops-list {
    margin-top: 20px;
  }
}

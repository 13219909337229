.consign-with-us-fourth-step__title {
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
  color: var(--white1);
  text-align: center;
  letter-spacing: -0.02em;
}

.consign-with-us-fourth-step__list {
  margin-top: 20px;
  margin-bottom: 40px;
  padding-left: 20px;
}

.consign-with-us-fourth-step__list-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--white1);
}

.consign-with-us-fourth-step__warning {
  margin-bottom: 40px;
  background: linear-gradient(90.57deg, #e02323 0%, #825af6 100%);
  padding: 10px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--white1);
}

.consign-with-us-fourth-step__form {
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  width: 720px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  background-color: var(--black6);
  border: 1px solid var(--white1);
  border-radius: 20px;
}

.consign-with-us-fourth-step__address-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--white1);
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center;
}

.consign-with-us-fourth-step__address {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--grey1);
  text-align: center;
}

@media (max-width: 800px) {
  .consign-with-us-fourth-step__title {
    font-size: 16px;
  }

  .consign-with-us-fourth-step__table {
    margin-bottom: 40px;
  }

  .consign-with-us-fourth-step__form {
    padding-bottom: 20px;
    margin-left: 44px;
    margin-right: 44px;
    width: auto;
  }
}

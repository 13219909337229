.community {
  width: 100%;
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: relative;
  background: rgba(34, 29, 51, 0.25);
}

.community__image {
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: 280px;
  margin: 0 auto;
  opacity: 0.2;
}

.community__title {
  margin-top: 60px;
  margin-bottom: 80px;
  text-transform: uppercase;
  color: var(--white1);
  font-weight: 600;
  font-size: 33px;
  line-height: 40px;
  z-index: 2;
  text-align: center;
  padding: 0 30px;
}

@media (max-width: 800px) {
  .community {
    height: 218px;
    justify-content: center;
    margin-top: 80px;
  }

  .community__title {
    font-weight: 600;
    font-size: 19px;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 40px;
    max-width: 280px;
    letter-spacing: -0.02em;
    padding: 0;
  }

  .community__image {
    object-fit: cover;
    max-width: 135px;
  }
}

.learnings-header {
  height: 376px;
  width: 100%;
  margin-top: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.learnings-header__background {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  opacity: 0.5;
}

.learnings-header__text-wrapper {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-self: center;
}

.learnings-header__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 150%;
  color: var(--white1);
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0px 5px 20px #5289f7;
}

.learnings-header__description {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  max-width: 800px;
  color: var(--white1);
  text-align: center;
}

@media (max-width: 850px) {
  .learnings-header__title {
    font-size: 33px;
  }

  .learnings-header__description {
    max-width: 350px;
    font-size: 14px;
  }
}

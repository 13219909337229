.consign-with-us-second-step__row {
  display: flex;
  gap: 40px;
}

.consign-with-us-second-step__fields {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.consign-with-us-second-step__add-card-type {
  width: 280px;
  height: 31px;
  background: var(--purple3);
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--white1);
  cursor: pointer;
}

.consign-with-us-second-step__add-card-type-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--white1);
  margin-left: 40px;
}

.consign-with-us-second-step__remove-button {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 105px;
  height: 31px;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  color: var(--white1);
  border: 1px solid var(--red1);
  border-radius: 20px;
  cursor: pointer;
  margin-top: 37px;
  justify-content: center;
}

@media (max-width: 800px) {
  .consign-with-us-second-step__row {
    flex-direction: column;
    gap: 30px;
  }
}
@media (max-width: 576px) {
  .consign-with-us-second-step__add-card-type {
    width: 100%;
  }

  .consign-with-us-second-step__add-card-type-text {
    margin-left: 20px;
  }

  .consign-with-us-second-step__fields {
    gap: 50px;
  }

  .consign-with-us-second-step__remove-button {
    align-self: center;
    margin-top: 0;
  }
}

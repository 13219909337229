.title {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  background-color: var(--purple7)
}

.title__text {
  color: white;
  text-shadow: -5px 5px 45px white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
}
.form {
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  width: 720px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  background-color: var(--black6);
  border: 1px solid var(--white1);
  border-radius: 20px;
}

@media (max-width: 800px) {
  .form {
    padding-bottom: 20px;
    margin-left: 44px;
    margin-right: 44px;
    width: auto;
  }
}

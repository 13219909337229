.blogs-cards {
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 790px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.blogs-cards__item-wrapper {
  gap: 40px;
  display: flex;
  margin-bottom: 60px;
  flex-direction: column;
}

@media (max-width: 850px) {
  .blogs-cards {
    justify-content: center;
    gap: 40px;
    max-width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }

  .blogs-cards .blog__card {
    width: 240px;
  }
  .blogs-cards {
    margin-top: 40px;
  }
}

@media (max-width: 576px) {
  .blogs-cards {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
}

.own-anything__wrapper {
  padding-top: 184px;
  max-width: 1130px;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  display: flex;
}

.own-anything__text-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.own-anything__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 150%;
  color: var(--white1);
}

.own-anything__text {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: var(--white1);
  margin-top: 10px;
  margin-bottom: 40px;
  max-width: 575px;
  text-align: left;
}

.own-anything__video {
  width: 50%;
  margin-top: -100px;
}

@media (max-width: 1130px) {
  .own-anything__wrapper {
    padding: 0 33.25px;
    padding-top: 184px;
  }
}

@media (max-width: 800px) {
  .own-anything__wrapper {
    margin-top: 0;
    padding-top: 20px;
    flex-direction: column-reverse;
    align-items: center;
  }

  .own-anything__video {
    width: 100%;
    margin-top: 0;
  }

  .own-anything__text-wrapper {
    align-items: center;
  }

  .own-anything__title {
    font-weight: 600;
    font-size: 33px;
    line-height: 150%;
  }

  .own-anything__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    max-width: 262px;
    text-align: center;
  }
}

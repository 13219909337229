.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 50px;
  height: 104px;
  width: 100%;
  background: var(--black2);
  z-index: 2000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.header__logo {
  width: 223px;
  height: 100%;
  cursor: pointer;
}

.header__links-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 60px;
}

.header__link {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: var(--white1);
}

.header__link:hover,
.header__link--active {
  color: var(--purple1);
}

.header__auth-wrapper {
  display: flex;
  gap: 40px;
  margin-left: 50px;
}

.header__mobile-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 104px;
  width: 100%;
  background: var(--black2);
  z-index: 2000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.header__menu-icon {
  position: absolute;
  z-index: 2;
}

@media (max-width: 1300px) {
  .header__auth-wrapper {
    margin-left: auto;
  }
  .header__links-wrapper {
    gap: 40px;
  }
  .header__auth-wrapper {
    gap: 20px;
  }
  .header__wrapper {
    padding: 0 20px;
  }
}

@media (max-width: 1160px) {
  .header__links-wrapper {
    gap: 20px;
  }
}

@media (max-width: 1020px) {
  .header__links-wrapper {
    gap: 10px;
  }
  .header__link {
    font-size: 14px;
  }
}

@media (max-width: 800px) {
  .header__links-wrapper {
    flex-direction: column;
    position: absolute;
    top: 104px;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--black2);
    gap: 60px;
    justify-content: flex-start;
    padding-top: 100px;
    z-index: 1000;
    position: fixed;
  }
  .header__link {
    font-size: 16px;
  }
  .header__auth-wrapper {
    margin-left: 0;
    margin-top: 60px;
    flex-direction: column;
    gap: 40px;
  }
  .header__logo {
    height: 79px;
    width: 280px;
    object-fit: cover;
  }

  .header__mobile-right-stub {
    height: 30px;
    min-width: 30px;
    margin-right: 40px;
  }
  .header__mobile-left {
    min-width: 30px;
    height: 30px;
    position: relative;
    margin-left: 40px;
  }
}

@media (max-width: 410px) {
  .header__mobile-right-stub {
    margin-right: 15px;
  }

  .header__mobile-left {
    margin-left: 15px;
  }
}

@media (max-width: 370px) {
  .header__mobile-right-stub {
    margin-right: 10px;
  }

  .header__mobile-left {
    margin-left: 10px;
  }
}

.blog {
  width: 100%;
  height: 100%;
  margin-top: 104px;
}

.blog__header-line {
  background-color: var(--purple3);
  height: 10px;
  width: 100%;
}

.blog__go-back-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-top: 50px;
  margin-bottom: 70px;
  margin-left: 110px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-transform: uppercase;
  color: var(--white1);
  letter-spacing: 0.04em;
}

.blog__go-back-img {
  width: 24px;
  height: 24px;
}

.blog__content {
  max-width: 960px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 50px;
}

.blog__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 150%;
  color: var(--white1);
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
}

.blog__date {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--white1);
  text-align: center;
}

.blog__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--white1);
  width: 100%;
}

.blog__text-bold {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: var(--white1);
  width: 100%;
}

.blog__sub-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: var(--white1);
  width: 100%;
}

.blog__full-width-img {
  width: 100%;
}

.blog__center-img {
  margin: 0 auto;
}

.blog__list {
  width: 100%;
  margin: 0;
  padding-left: 25px;
}

.blog__list-row {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--white1);
}

.blog__list-row::marker {
  font-size: 12px;
}

.blog__pagination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 110px;
  margin-bottom: 40px;
}

.blog__double-arrow-icon {
  width: 24px;
  height: 24px;
}
.blog__pagination-button {
  width: 117px;
  height: 44px;
  border: 1px solid var(--white1);
  border-radius: 20px;
  background-color: var(--black8);
  color: var(--white1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.04em;
  cursor: pointer;
}

.blog__pagination-button--disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

@media (max-width: 960px) {
  .blog__content {
    max-width: 100%;
    padding: 0 24px;
  }
}

@media (max-width: 800px) {
  .blog__go-back-wrapper {
    margin-left: 40px;
  }

  .blog__title {
    font-weight: 600;
    font-size: 33px;
    line-height: 150%;
  }

  .blog__pagination-wrapper {
    margin: 0 40px;
    margin-bottom: 40px;
  }
}

@media (max-width: 450px) {
  .blog__center-img {
    width: stretch;
    height: 100%;
  }
}

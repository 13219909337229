.press-cards {
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

@media (max-width: 850px) {
  .press-cards {
    justify-content: center;
    gap: 10px;
    max-width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }

  .press-cards .press-card__card {
    width: 240px;
  }
  .press-cards {
    margin-top: 40px;
  }
}

@media (max-width: 576px) {
  .press-cards {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
}

.consign-with-us-result-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
}

.consign-with-us-result-step__img {
  width: 201.25px;
  height: 212px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.consign-with-us-result-step__title {
  font-weight: 600;
  font-size: 23px;
  line-height: 150%;
  color: var(--white1);
  margin-bottom: 20px;
}

.consign-with-us-result-step__subtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: var(--white1);
  margin-bottom: 80px;
  max-width: 400px;
  text-align: center;
}

.consign-with-us-result-step__error-subtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: var(--white1);
  max-width: 400px;
  text-align: center;
}

.consign-with-us-result-step__form-line {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #a796eb;
  text-decoration: underline;
  cursor: pointer;
}

.consign-with-us-result-step__address-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: var(--white1);
  margin-bottom: 10px;
}

.consign-with-us-result-step__address {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--grey1);
  text-align: center;
}

.consign-with-us-result-step__table {
  margin-top: 40px;
  min-width: 400px;
}

@media (max-width: 800px) {
  .consign-with-us-result-step {
    margin-left: 14px;
    margin-right: 14px;
  }
  .consign-with-us-result-step__img {
    margin-top: 80px;
    margin-bottom: 40px;
  }
  .consign-with-us-result-step__table {
    min-width: auto;
  }
}

.terms-of-use {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-top: 104px;
}

.terms-of-use__text-section-wrapper {
  max-width: 980px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 60px 24px;
}

.terms-of-use__header-text {
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: var(--white1);
}

.terms-of-use__date-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--white1);
  margin-top: 40px;
  margin-bottom: 20px;
}

.terms-of-use__section-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--white1);
  margin-bottom: 20px;
  margin-top: 20px;
}

.terms-of-use__section-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--white1);
  margin-bottom: 20px;
}

.terms-of-use__link {
  color: var(--purple8);
  text-decoration: underline;
  cursor: pointer;
  word-break: break-word;
}

.terms-of-use__additional-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 40px;
  color: var(--purple1);
}

@media (max-width: 980px) {
  .terms-of-use__text-section-wrapper {
    padding: 0 24px;
  }
}

@media (max-width: 800px) {
  .terms-of-use__header-text,
  .terms-of-use__date-text {
    padding-left: 10px;
  }
}

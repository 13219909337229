.press-card__card {
  width: 240px;
  height: 367px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--white1);
  background-color: transparent;
  padding: 20px;
  cursor: pointer;
}

.press-card__card:hover {
  background-color: var(--black8);
}

.press-card__card-img {
  width: 200px;
  height: 120px;
  margin-bottom: 30px;
}

.press-card__card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  text-align: center;
}

.press-card__card-text {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--white1);
}

.press-card__card-email {
  width: 200px;
  height: 37px;
  color: var(--white1);
  background-color: var(--purple3);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
}

.press-card__card-header-img {
  width: 200px;
  height: 40px;
  margin-bottom: 20px;
}

@media (max-width: 800px) {
  .press-card__card {
    height: auto;
    border: none;
    background-color: transparent;
    padding: 0;
    width: 100%;
  }

  .press-card__card:hover {
    background-color: transparent;
  }

  .press-card__card-wrapper:hover {
    background-color: var(--black8);
  }

  .press-card__card-wrapper {
    width: 240px;
    height: 367px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--white1);
    background-color: transparent;
    padding: 20px;
    margin: 0 auto;
  }
}

.about-us__collect-section {
  display: flex;
  justify-content: center;
  gap: 60px;
  overflow: hidden;
  position: relative;
  height: auto;
  margin-bottom: 60px;
  margin-top: 100px;
}

.about-us__collect-section-card {
  display: flex;
  flex-direction: column;
  padding: 20px 8px;
  align-items: center;
  width: 250px;
  height: auto;
  border-radius: 20px;
  position: relative;
  z-index: 2;
}

.about-us__collect-section-card-title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--white1);
  margin-top: 10px;
}

.about-us__collect-section-card-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--white1);
  margin-top: 10px;
  text-align: center;
}

@media (max-width: 1250px) {
  .about-us__collect-section {
    gap: 20px;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 800px) {
  .about-us__collect-section-card {
    max-width: 250px;
    padding-top: 8px;
  }

  .about-us__collect-section {
    gap: 8px;
  }

  .about-us__collect-section-card-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
  }

  .about-us__collect-section-card-title {
    margin-top: 0;
  }
}

@media (max-width: 576px) {
  .about-us__collect-section-card {
    max-width: 194px;
  }
}

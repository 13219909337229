.discord {
  width: 100%;
  height: 482px;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  padding-left: 240px;
  z-index: 2;
  position: relative;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
}

.discord__image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  object-fit: cover;
}

.discord__title-wrapper {
  margin-top: 144.5px;
  margin-bottom: 10px;
  z-index: 2;
}

.discord__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 150%;
  color: var(--white1);
  text-transform: uppercase;
}

@media (max-width: 800px) {
  .discord {
    padding: 0;
    align-items: center;
    height: auto;
    margin-top: 90px;
    justify-content: center;
  }
  .discord__title:last-child {
    text-transform: none;
  }
  .discord__title-wrapper {
    margin-top: 0px;
    text-align: center;
    margin-bottom: 10px;
  }

  .discord__title {
    font-weight: 600;
    font-size: 23px;
    line-height: 150%;
  }

  .discord__mobile-image {
    object-fit: cover;
    width: 100%;
    margin-bottom: 10px;
  }
}

.about-us__stories {
  background: var(--purple6);
  position: relative;
  z-index: 2;
}

.about-us__story-card-img {
  width: 100%;
  height: 289px;
  object-fit: cover;
}

.about-us__stories-slider-wrapper {
  max-width: 1500px;
  margin: 0 auto;
}

.about-us__story-card {
  height: 289px;
  width: 100%;
}
.about-us__stories .slick-prev,
.about-us__stories .slick-next {
  height: 50px;
  width: 50px;
  z-index: 2;
}

.about-us__stories .slick-prev {
  left: 180.5px;
}

.about-us__stories .slick-next {
  right: 180.5px;
}

.about-us__stories {
  position: relative;
}

.about-us__story-card-text-wrapper {
  position: absolute;
  top: 40px;
  margin-left: 270.5px;
  max-width: 470px;
  z-index: 2;
}

.about-us__story-card-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--white1);
}

.about-us__story-card-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--white1);
  margin-top: 10px;
}

.about-us__stories .slick-dots {
  bottom: 30px;
}

.about-us__stories .slick-dots li.slick-active button:before {
  color: var(--purple3);
  opacity: 1;
}

.about-us__stories .slick-dots li button:before {
  font-size: 10px;
  color: var(--white2);
  opacity: 1;
}

@media (max-width: 1080px) {
  .about-us__story-card {
    height: 360px;
    position: relative;
  }
  .about-us__story-card-text-wrapper {
    position: relative;
    margin: 0 auto;
    top: 0;
    margin-top: 28px;
    z-index: 2;
  }

  .about-us__story-card-text {
    max-width: 346px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    margin-top: 20px;
  }

  .about-us__story-card-title {
    text-align: left;
    max-width: 346px;
    margin: 0 auto;
  }

  .about-us__story-card-img {
    margin-top: 28px;
    height: auto;
    position: absolute;
    bottom: 10px;
    margin-top: 0;
    object-fit: cover;
  }

  .about-us__stories .slick-next {
    right: 10px;
  }

  .about-us__stories .slick-prev {
    left: 10px;
  }
}

@media (max-width: 600px) {
  .about-us__stories .slick-next {
    width: 40px;
  }

  .about-us__stories .slick-prev {
    width: 40px;
  }

  .about-us__story-card-title,
  .about-us__story-card-text {
    max-width: 325px;
  }

  .about-us__story-card-img {
    max-height: 114px;
  }
}

@media (max-width: 450px) {
  .about-us__stories .slick-next {
    right: 0;
  }

  .about-us__stories .slick-prev {
    left: 0;
  }
}

*,
*::before,
*::after,
*:focus {
  border: none;
  outline: none;
  box-sizing: border-box;
}

html,
body {
  font-size: 10px;
  line-height: 1.15;
  text-align: left;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh;
  color: var(--white1);
  font-family: "Poppins", sans-serif;
  font-weight: normal;
}

body:before {
  background: var(--black1);
  bottom: 0;
  content: "";
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
}

#root {
  min-height: 100vh;
}

.App {
  background: var(--globalBackground);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a,
div,
input,
button,
small,
label,
tr,
li {
  font-size: 1.1rem;
  transition: none;
}

li::marker {
  font-size: 10px;
}

textarea {
  resize: none;
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
dialog,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

dfn {
  font-style: normal;
}

b,
strong {
  font-weight: bolder;
}

/* Link */

a {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0;
}

button {
  border-radius: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

option {
  border: none;
  outline: none;
  box-shadow: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--black6);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 1px solid var(--white1);
  border-radius: 20px;
  background: linear-gradient(131.67deg, var(--blue1) 0%, var(--purple2) 100%);
}

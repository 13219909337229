.drops-list-item {
  min-height: 277px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px;
  gap: 50px;
  background: var(--black2);
  border: 1px solid var(--white1);
  border-radius: 20px;
  color: inherit;
}

.drops-list-item__image {
  height: 191.4px;
  width: 120px;
  max-width: 120px;
  max-height: 191.4px;
}

.drops-list-item__text-container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
}

.drops-list-item__info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.drops-list-item__state {
  display: inline-block;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 23px;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  --bg-color-start: var(--purple2);
  --bg-color-end: var(--pink1);
  background: linear-gradient(
    135deg,
    var(--bg-color-start) 0%,
    var(--bg-color-end) 100%
  );
}

.drops-list-item__state--offering {
  --bg-color-start: var(--blue1);
  --bg-color-end: var(--purple2);
}

.drops-list-item__name {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.02em;
  --max-lines: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  line-clamp: var(--max-lines);
  -webkit-line-clamp: var(--max-lines);
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.drops-list-item__trade-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.drops-list-item__icon-growth {
  width: 24px;
  height: 24px;
  color: var(--green2);
}

.drops-list-item__icon-growth--falling {
  color: var(--red1);
  transform: rotate(180deg);
}

.drops-list-item__price-container {
  font-weight: 600;
  color: var(--grey2);
}

.drops-list-item__price {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.02em;
}

.drops-list-item__currency {
  font-size: 12px;
  line-height: 17px;
  margin-left: 5px;
}

.drops-list-item .buttons__button {
  flex-shrink: 0;
  width: 180px;
  height: auto;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  border-radius: 999px;
  text-transform: uppercase;
  line-height: 1;
}

@media (max-width: 800px) {
  .drops-list-item {
    height: 200px;
    min-height: initial;
    padding: 20px;
    gap: 20px;
  }

  .drops-list-item__image {
    height: 100%;
    width: 148px;
  }

  .drops-list-item__text-container {
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
  }

  .drops-list-item__state {
    padding: 4px 20px;
    font-size: 12px;
    line-height: 1.5;
  }

  .drops-list-item__name {
    font-size: 14px;
    line-height: 1.5;
    --max-lines: 2;
  }

  .drops-list-item__trade-container {
    gap: 5px;
  }

  .drops-list-item__icon-growth {
    width: 14px;
    height: 14px;
  }

  .drops-list-item__price-container {
    gap: 2px;
  }

  .drops-list-item__price {
    font-size: 14px;
    line-height: 1.5;
  }

  .drops-list-item__currency {
    font-size: 8px;
    line-height: 1.5;
  }

  .drops-list-item .buttons__button {
    width: 100%;
    max-width: 160px;
    text-align: center;
    min-height: initial;
  }
}

@media (max-width: 375px) {
  .drops-list-item__trade-container {
    display: none;
  }

  .drops-list-item .buttons__button {
    font-size: 14px;
    padding: 8px;
  }
}

.media__wrapper {
  margin-top: 104px;
}

.submit-form-container {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.submit-form-container__success {
  font-size: 42px;
  font-weight: 600;
}

@media (max-width: 600px) {
  .submit-form-container {
    padding: 40px 44px;
  }
}

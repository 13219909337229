.form-textarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.form-textarea__label {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: var(--white1);
  letter-spacing: 0.04em;
  text-transform: uppercase;
  display: flex;
  margin-bottom: 10px;
}

.form-textarea__required {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: var(--red1);
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding-left: 5px;
}

.form-textarea__input {
  background: var(--white1);
  border-radius: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--black1);
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  padding-top: 5px;
  resize: none;
}

.form-textarea__input-wrapper {
  border-radius: 20px;
}

.form-textarea__input-wrapper--error {
  border: solid 2px transparent;
  background-image: linear-gradient(#fff 0%, #fff 100%),
    linear-gradient(90.57deg, #e02323 0%, #825af6 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.form-textarea__error-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: var(--red1);
  margin-top: 5px;
}

.consign-with-us-info__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--white1);
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 80px;
}

.consign-with-us-info__table-wrapper {
  max-width: 660px;
  width: 660px;
  display: flex;
  padding-top: 15px;
  padding-bottom: 25px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 40px auto;
  background: var(--black9);
  border-radius: 20px;
}

.consign-with-us-info__table-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.consign-with-us-info__table-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.04em;
  color: var(--white1);
  text-align: center;
  text-transform: uppercase;
}

.consign-with-us-info__table-title-divider {
  background-color: var(--grey3);
  height: 1px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.consign-with-us-info__table-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.consign-with-us-info__table-row {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--white1);
}

@media (max-width: 800px) {
  .consign-with-us-info__text {
    margin-top: 40px;
    font-size: 14px;
    margin-left: 49px;
    margin-right: 49px;
  }

  .consign-with-us-info__table-wrapper {
    width: 330px;
    max-width: 330px;
  }

  .consign-with-us-info__table-title,
  .consign-with-us-info__table-row {
    font-size: 12px;
  }
}

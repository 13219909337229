.privacy-policy {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-top: 104px;
}

.privacy-policy__text-section-wrapper {
  max-width: 980px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 60px 24px;
}

.privacy-policy__header-text {
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: var(--white1);
}

.privacy-policy__date-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--white1);
  margin-top: 40px;
  margin-bottom: 20px;
}

.privacy-policy__section-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--white1);
  margin-bottom: 20px;
  margin-top: 20px;
}

.privacy-policy__section-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--white1);
  margin-bottom: 20px;
}

.privacy-policy__link {
  text-decoration: underline;
  cursor: pointer;
  word-break: break-word;
}

.privacy-policy__paragraph-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.privacy-policy__additional-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 40px;
  color: var(--purple1);
}

.privacy-policy__section-list {
  margin: 0;
  padding-left: 25px;
}

.privacy-policy__section-list-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--white1);
}

@media (max-width: 980px) {
  .privacy-policy__text-section-wrapper {
    padding: 0 24px;
  }
}

@media (max-width: 800px) {
  .privacy-policy__header-text,
  .privacy-policy__date-text {
    padding-left: 10px;
  }
}

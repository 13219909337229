.consign-with-us-steps {
  display: flex;
  gap: 50px;
  justify-content: center;
  width: 100%;
  margin: 56px 0;
}

.consign-with-us-steps__step {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.consign-with-us-steps__step-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: double 3px transparent;
  background-image: linear-gradient(#000f26 0%, #000f26 100%),
    linear-gradient(131.67deg, #3f8cff 0%, #825af6 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--white1);
  cursor: pointer;
}

.consign-with-us-steps__step-circle--active {
  background: linear-gradient(131.67deg, #3f8cff 0%, #825af6 100%);
  border: none;
}

.consign-with-us-steps__step-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: var(--white1);
}

@media (max-width: 800px) {
  .consign-with-us-steps {
    gap: 12.5px;
    margin-bottom: 36px;
  }
  .consign-with-us-steps__step-circle {
    width: 65px;
    height: 65px;
    font-size: 10px;
  }

  .consign-with-us-steps__step-description {
    font-size: 10px;
    text-align: center;
  }
  .consign-with-us-steps__step-arrow {
    padding-bottom: 20px;
  }
}

@media (max-width: 415px) {
  .consign-with-us-steps__step-circle {
    width: 50px;
    height: 50px;
    font-size: 8px;
  }
  .consign-with-us-steps {
    gap: 5px;
  }
}

.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.form-input__label {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: var(--white1);
  letter-spacing: 0.04em;
  text-transform: uppercase;
  display: flex;
  margin-bottom: 10px;
}

.form-input__required {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: var(--red1);
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding-left: 5px;
}

.form-input__input {
  background: var(--white1);
  border-radius: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--black1);
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.form-input__input-wrapper {
  border-radius: 20px;
}

.form-input__input-wrapper--error {
  border: solid 2px transparent;
  background-image: linear-gradient(#fff 0%, #fff 100%),
    linear-gradient(90.57deg, #e02323 0%, #825af6 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.form-input__error-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: var(--red1);
  margin-top: 5px;
}

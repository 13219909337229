.form-select {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.form-select__label {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: var(--white1);
  letter-spacing: 0.04em;
  text-transform: uppercase;
  display: flex;
  margin-bottom: 10px;
}

.form-select__select-wrapper--error {
  border: solid 2px transparent;
  background-image: linear-gradient(#fff 0%, #fff 100%),
    linear-gradient(90.57deg, #e02323 0%, #825af6 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 20px;
}

.form-select__required {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: var(--red1);
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding-left: 5px;
}

.form-select__arrow {
  position: absolute;
  right: 10px;
  width: 16px;
  height: 16px;
  bottom: 8px;
  pointer-events: none;
}

.form-select__error-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: var(--red1);
  margin-top: 5px;
}

.about-us__join-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  position: relative;
  overflow: hidden;
}

.about-us__join-us-title {
  font-weight: 600;
  font-size: 33px;
  line-height: 40px;
  color: var(--white1);
  position: relative;
  z-index: 2;
  margin-bottom: 80px;
}

.about-us__position-section {
  display: flex;
  justify-content: space-between;
  max-width: 924px;
  width: 100%;
  position: relative;
  z-index: 2;
}

.about-us__position-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.about-us__position-title {
  color: var(--white1);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}

.about-us__position-text {
  color: var(--white1);
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}

.about-us__position-divider {
  height: 1px;
  width: 100%;
  background-color: var(--white1);
  margin-top: 30px;
  margin-bottom: 40px;
  max-width: 924px;
  position: relative;
  z-index: 2;
}

.about-us__position-divider:last-child {
  margin-bottom: 60px;
}

@media (max-width: 1000px) {
  .about-us__position-section,
  .about-us__position-divider {
    max-width: 700px;
  }
}

@media (max-width: 800px) {
  .about-us__join-us {
    padding-top: 40px;
    padding-left: 16.5px;
    padding-right: 16.5px;
  }

  .about-us__join-us-title {
    margin-bottom: 40px;
    font-size: 23px;
  }

  .about-us__position-section {
    flex-direction: column;
    align-items: center;
  }

  .about-us__position-text {
    text-align: center;
  }

  .about-us__position-text-wrapper {
    margin-bottom: 40px;
  }

  .about-us__position-section,
  .about-us__position-divider {
    max-width: 100%;
  }

  .about-us__position-title {
    font-size: 14px;
  }
}

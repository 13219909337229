.co-own__wrapper {
  margin-top: 140px;
  max-width: 1130px;
  margin-left: auto;
  margin-right: auto;
  height: auto;
}

.co-own__wrapper .slick-prev {
  left: 35px;
  z-index: 2;
  width: 50px;
  height: 50px;
}

.co-own__wrapper .slick-next {
  right: 35px;
  z-index: 2;
  width: 50px;
  height: 50px;
}

.co-own__wrapper .slick-dots {
  bottom: -35px;
}

.co-own__wrapper .slick-dots li.slick-active button:before {
  color: var(--purple3);
  opacity: 1;
}

.co-own__wrapper .slick-dots li button:before {
  font-size: 10px;
  color: var(--white2);
  opacity: 1;
}

.co-own__img {
  border-radius: 20px;
  object-fit: cover;
  width: 100%;
}

.co-own__img-wrapper {
  cursor: pointer;
}

@media (max-width: 1130px) {
  .co-own__wrapper {
    padding: 0 33.25px;
    padding-top: 0;
  }
}


@media (max-width: 800px) {
  .co-own__wrapper {
    padding-top: 20px;
  }
  .co-own__img-wrapper {
    height: 235px;
  }
  .co-own__img {
    height: 235px;
  }

  .co-own__wrapper .slick-next {
    right: 0;
  }

  .co-own__wrapper .slick-prev {
    left: 0;
  }
}

.consign-with-us-first-step__row {
  display: flex;
  gap: 40px;
}

@media (max-width: 800px) {
  .consign-with-us-first-step__row {
    flex-direction: column;
    gap: 30px;
  }
}

.email-subscribe {
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  max-width: 500px;
  min-width: 0px;
  margin-bottom: 46px;
}

.email-subscribe__title {
  font-size: 19px;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 10px;
}

.email-subscribe__content {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.email-subscribe__input {
  display: flex;
  flex: 1 1 auto;
}

.email-subscribe__input .input-container {
  flex: 1 1 auto;
}

.email-subscribe__input__error {
  color: var(--red1);
  height: calc(1em * 1.5);
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.5;
}

.email-subscribe__input__button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;
  background-color: var(--white1);
  color: var(--black1);
  cursor: pointer;
}

.email-subscribe__input__button:hover {
  background-color: var(--grey1);
}

.email-subscribe__success {
  font-size: 28px;
  line-height: 42px;
  font-weight: 600;
  margin: auto;
}

@media (max-width: 800px) {
  .email-subscribe {
    padding: 0;
    margin: 0 auto;
    width: 100%;
  }

  .email-subscribe__title {
    font-size: 16px;
  }

  .email-subscribe__content {
    font-size: 12px;
  }
  .email-subscribe__input__button {
    min-width: 109px;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.04em;
    height: 38px;
  }

  .email-subscribe__input .inputs__input {
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.04em;
    height: 38px;
  }
  .email-subscribe__success {
    font-size: 19px;
  }
}

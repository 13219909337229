.consign-with-us-header__wrapper {
  width: 100%;
  height: 360px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.consign-with-us-header__img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.consign-with-us-header__text-wrapper {
  display: flex;
  z-index: 2;
  flex-direction: column;
}

.consign-with-us-header__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 150%;
  background: linear-gradient(90.57deg, var(--purple3) 0%, var(--green1) 100%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.consign-with-us-header__title-text {
  -webkit-text-stroke: 2px var(--white1);
  text-transform: uppercase;
}

.consign-with-us-header__subtitle-wrapper {
  margin-top: 15px;
  padding: 5px 10px;
  background-color: var(--black9);
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--white1);
}

.consign-with-us-header__subtitle-company-text {
  background: radial-gradient(
    circle at center,
    var(--blue2) 100%,
    #886bee 100%,
    var(--white1) 20%
  );
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 800px) {
  .consign-with-us-header__title-text {
    text-align: center;
    font-size: 33px;
  }
  .consign-with-us-header__subtitle-wrapper {
    font-size: 16px;
  }
}

.drops-search {
  flex-grow: 1;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  margin: 0 92px;
  gap: 10px;
  background: var(--black2);
  border: 1px solid var(--white1);
  border-radius: 999px;
}

.drops-search:hover,
.drops-search:focus-within {
  background: var(--purple4);
}

.drops-search__icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.drops-search__icon--close {
  padding: 3px;
  border-radius: 50%;
  cursor: pointer;
}

.drops-search__icon--close:hover {
  background: var(--purple5);
}

.drops-search__input {
  flex-grow: 1;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.04em;
  color: inherit;
  text-transform: uppercase;
  text-overflow: ellipsis;
}

@media (max-width: 800px) {
  .drops-search{
    margin: 0 40px;
    max-width: 600px;
  }

  .drops-search__input {
    font-size: 12px;
  }
}

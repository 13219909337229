.result-table {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: var(--black9);
  border-radius: 20px;
  margin-bottom: 80px;
  gap: 10px;
}

.result-table__row {
  display: flex;
  gap: 10px;
}

.result-table__extra-row {
  display: flex;
  gap: 40px;
}

.result-table__row-name {
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: var(--grey1);
}

.result-table__row-value {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: var(--grey1);
}

@media (max-width: 800px) {
  .result-table__title {
    font-size: 16px;
  }

  .result-table__table {
    margin-bottom: 40px;
  }
  .result-table__row {
    flex-direction: column;
  }
  .result-table__extra-row{ 
    gap: 10px;
    flex-direction: column;
  }
}
